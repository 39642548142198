import React, { useEffect, useState } from 'react';
import './Embed.css';

import { TimerDark as Timer, Spinner8Bit } from '../../components/Spinner';
import config from '../../config';
import BasicNft from '../../components/NftToken/BasicNft';
import AokiNft from '../../components/NftToken/AokiNft';
import EnsNft from '../../components/NftToken/EnsNft';

const Nft = (props) => {
  if (props.clientName == 'aoki') {
    return <AokiNft {...props} />;
  } else if (props.clientName == 'ens_nft') {
    return <EnsNft {...props} />;
  } else return <BasicNft {...props} />;
};

const Spinner = ({ clientName }) => {
  if (clientName == 'aoki') {
    return <Spinner8Bit></Spinner8Bit>;
  } else return <Timer></Timer>;
};

const Embed = (props) => {
  const [format, setFormat] = useState(null);
  const [type, setType] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [traits, setTraits] = useState(null);
  const [timebasedSlides, setTimebasedSlides] = useState([]);
  const [loading, setLoading] = useState(null);
  const [showError, setShowError] = useState(null);
  const [showForgeTrait, setShowForgeTrait] = useState(null);
  const [showRedeemableTrait, setShowRedeemableTrait] = useState(null);
  const { client, series, tokenId } = props.match.params;

  useEffect(() => {
    if (client == 'ens_nft') {
      fetchMetadata(`${config.BASE_URI}${client}/${tokenId}`);
    } else {
      fetchMetadata(`${config.BASE_URI}${client}/${series}/${tokenId}`);
    }
  }, [client, series, tokenId]);

  const fetchMetadata = async (uri) => {
    setLoading(true);
    const metadata = await fetch(uri)
      .then((res) => res.json())
      .catch((err) => console.error(err));
    if (metadata && metadata.code != '404') {
      if (metadata.format) {
        setFormat(metadata.format);
      } else setFormat('portrait');
      if (metadata.type) {
        setType(metadata.type);
      } else setType('regular');
      setVideoUrl(metadata.original_art_url || metadata.animation_url);
      setImageUrl(metadata.image);
      setTraits(metadata.traits);
      setShowRedeemableTrait(metadata.redeemable);
      if (client == 'ens_nft') {
        setShowForgeTrait(metadata.claimable);
      } else {
        setShowForgeTrait(metadata.forge);
      }
      if (metadata.slides) {
        getTimebasedSlides(metadata.slides);
      }
    } else {
      setShowError(true);
    }
    setLoading(false);
  };

  const getTimebasedSlides = (slides) => {
    Object.values(slides).forEach((slide) => {
      setTimebasedSlides((timebasedSlides) => [...timebasedSlides, slide.url]);
    });
    setTimebasedSlides((timebasedSlides) => [...timebasedSlides, timebasedSlides[0]]);
  };
  // if true => we are on ..../wallet
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const isWallet = params.get('isWallet');
  const autoStart = params.get('autoStart');

  return (
    <>
      {loading ? (
        <div className="e-spinner-container">
          <Spinner clientName={client}></Spinner>
        </div>
      ) : showError ? (
        <div className="error-container">
          <div className="text">
            <span className="text-exclamation">!</span>
            <br></br>Invalid Metadata
          </div>
        </div>
      ) : (
        <>
          <Nft
            format={format}
            type={type}
            imageUrl={imageUrl}
            videoUrl={videoUrl}
            displayFullScreen={false}
            autoPlay={true}
            timebasedSlides={timebasedSlides}
            traits={traits}
            isWallet={isWallet}
            autoStart={autoStart}
            clientName={client}
            showForgeTrait={showForgeTrait}
            showRedeemableTrait={showRedeemableTrait}
          />
        </>
      )}
    </>
  );
};

export default Embed;
