import React from "react";
import "./Spinner.css";

const TimerDark = () => {
	return (
		<div className="lds-ripple">
			<div></div>
			<div></div>
		</div>
	);
};

export default TimerDark;
