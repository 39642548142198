import React, { useState, useEffect } from 'react';
import './TraitPopup.css';
import closeIcon from '../../assets/images/close.png';
import icon from '../../assets/images/icon-redeemable.png';

const TraitPopup = ({ redeemable }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className={`trait-popup ${showPopup ? 'details-on' : ''}`}>
      <a
        className={`trait-btn btn ${showPopup ? 'details-on' : ''}`}
        onClick={() => setShowPopup(true)}
        style={{ width: '60px' }}
      >
        <img src={icon}></img>
      </a>
      <div
        className={`details-slide text-center ${!redeemable && 'redeemed'}`}
        style={{ height: '100%', maxHeight: 'unset' }}
      >
        <div
          className="close-btn"
          onClick={(e) => {
            setShowPopup(false);
          }}
        >
          <img src={closeIcon} alt="close" />
        </div>
        <div className={`details-content`}>
          <h3 className="mt-0 mb-0">
            Infinite <br></br>Objects
          </h3>
          <p>Use this trait to redeem an Infinite Object that will play Level 1 It Came From Above!</p>
        </div>
      </div>
    </div>
  );
};

export default TraitPopup;
