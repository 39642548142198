import React, { useState } from 'react';
import './TraitPopup.css';
import closeIcon from '../../assets/images/close.png';
import forgeIcon from '../../assets/images/sbook.svg';

const TraitPopup = ({ redeemable }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className={`trait-popup ${showPopup ? 'details-on' : ''}`}>
      <a className={`ens trait-btn btn ${showPopup ? 'details-on' : ''}`} onClick={() => setShowPopup(true)}>
        <img src={forgeIcon}></img>
      </a>
      <div className={`details-slide text-center ${!redeemable && 'redeemed'}`}>
        <div
          className="close-btn"
          onClick={(e) => {
            setShowPopup(false);
          }}
        >
          <img src={closeIcon} alt="close" />
        </div>
        <div className={`details-content`}>
          <img className="forge" height={40} src={forgeIcon}></img>
          <h3 className="mt-0 mb-0">Forge</h3>
          <p>This NFT is eligible for forge.</p>
        </div>
      </div>
    </div>
  );
};

export default TraitPopup;
