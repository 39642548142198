const dev = {
  PRODUCTION: false,
  DEPLOYED_NTW_NAME: 'goerli',
  DEPLOYED_CHAIN_ID: 5,
  INFURA_ID: 'a5e79e6ee9a14236b385e47849805596',
  FORTMATIC_KEY: 'pk_test_DD2BBA8AAA1D4FED',
  RPC_URL: 'https://goerli.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc',
  // RPC_URL: 'https://small-lingering-feather.rinkeby.quiknode.pro/4093cd8bffd26688802327779af8b485f8759f3e/',

  AGGREGATOR_URL: 'https://sorry.af/api/addresses/',
  BASE_URI: 'https://client-metadata-staging.herokuapp.com/api/',
  // BASE_URI: 'http://localhost:5000/api/',

  ETHERSCAN_URL: 'https://goerli.etherscan.io/',
};

const prod = {
  PRODUCTION: true,
  DEPLOYED_NTW_NAME: 'mainnet',
  DEPLOYED_CHAIN_ID: 1,
  INFURA_ID: 'a5e79e6ee9a14236b385e47849805596',
  FORTMATIC_KEY: 'pk_live_FBFF1F05F2879F29',
  RPC_URL: 'https://mainnet.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc',
  //RPC_URL: 'https://icy-weathered-glade.quiknode.pro/74e3e2900f41260b429e8ac065b8bee720298c94/',
  //AGGREGATOR_URL: 'https://aggregator.ether.cards/api/addresses/',
  AGGREGATOR_URL: 'https://sorry.af/api/addresses/',
  BASE_URI: 'https://client-metadata.ether.cards/api/',

  ETHERSCAN_URL: 'https://etherscan.io/',
};

const common = {
  DAPP_ID: 'b15a625a-c5b1-4b1c-a31b-e4072d006153',
};

// if use npm/yarn start,  NODE_ENV = "development"
// if use npm/yarn build,  NODE_ENV = "production"
let envConfig = prod; // process.env.NODE_ENV === "development" ? dev : prod
let config = { ...envConfig, ...common };

export default config;

// pointless comment for test commit
