import React, { useEffect, useState } from 'react';
import Media from '../Media/Media.jsx';
import EnsPopup from '../TraitPopup/EnsPopup.jsx';
import './EnsNft.css';

const BasicNft = ({
  format, //portrait or landscape
  type, //regular or time-based
  videoUrl,
  imageUrl,
  timebasedSlides, //to timebased => array of slides
  autoPlay,
  displayFullScreen,
  traits,
  style,
  isWallet,
  autoStart,
  clientName,
  showForgeTrait,
}) => {
  const [mediaType, setMediaType] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);

  useEffect(() => {
    if (videoUrl && videoUrl.endsWith('.mp4')) {
      setMediaType('video');

      setMediaUrl(videoUrl);
    } else {
      setMediaType('image');

      setMediaUrl(imageUrl);
    }
  }, [videoUrl, imageUrl]);

  return (
    <>
      <Media
        autoPlay={autoPlay}
        mediaType={mediaType}
        type={type}
        mediaUrl={mediaUrl}
        format={format}
        timebasedSlides={timebasedSlides}
        isWallet={isWallet}
        style={style}
        displayFullScreen={displayFullScreen}
        autoStart={autoStart}
        clientName={clientName}
        traitPopup={<>{showForgeTrait && <EnsPopup />}</>}
      />
    </>
  );
};

export default BasicNft;
