import { Embed } from './pages';

const routes = [
     {
          'path': '/embed/:client/:tokenId',
          'component': Embed,
          'exact': true
     },
     {
          'path': '/embed/:client/:series/:tokenId',
          'component': Embed,
          'exact': true
     }
];

export default routes;


