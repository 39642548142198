import { useEffect } from "react";
import "./App.css";
import { HashRouter, Route, Switch, useLocation } from 'react-router-dom';
import routes from "./Routes";


const AppWrapper = ({ children }) => {
  let location = useLocation();

  // useEffect(() => {
  //   if (location) {
  //     console.log(location)
  //   }
  // }, [location]);


  return <>{children}</>

}

const App = (props) => {
  return (
    <HashRouter basename="/">
      <AppWrapper>
        <Switch>
          {routes.map((route, key) => (
            <Route
              key={key}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
        </Switch>
      </AppWrapper>
    </HashRouter>
  );
};

export default App;
