import React, { useState, useEffect } from 'react';
import './TraitPopup.css';
import closeIcon from '../../assets/images/close.png';
import forgeIcon from '../../assets/images/icon-forge.png';

const DRTraitsPopup = ({ traits }) => {
  const [showPopup, setShowPopup] = useState(null);

  return (
    <>
      {traits && traits.length > 0 && (
        <div className={`trait-popup ${showPopup ? 'details-on' : ''}`}>
          {traits.map((trait, index) => {
            const topOffset = 20;
            const traitOffset = index * 40;
            const traitIcon = `${process.env.PUBLIC_URL}/traits/dirtyrobot/${trait.icon}`;
            return (
              <a
                key={trait.id}
                className={`trait-btn btn ${showPopup ? 'details-on' : ''}`}
                style={{
                  top: `${topOffset + traitOffset}px`,
                }}
                onClick={() => setShowPopup(trait)}
              >
                <img src={traitIcon}></img>
              </a>
            );
          })}
          <div className={`details-slide text-center`}>
            <div
              className="close-btn"
              onClick={(e) => {
                setShowPopup(null);
              }}
            >
              <img src={closeIcon} alt="close" />
            </div>
            <div className={`details-content`}>
              {showPopup && (
                <>
                  <img height={20} src={`${process.env.PUBLIC_URL}/traits/dirtyrobot/${showPopup.icon}`}></img>
                  <h3 className="mt-0 mb-0">{showPopup.name}</h3>
                  <p>{showPopup.description}</p>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DRTraitsPopup;
