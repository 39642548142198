import { useState, useEffect } from "react";

const useTimeEvolving = (start) => {
	const [sliderValue, setSliderValue] = useState(350);
	const [opacities, setOpacities] = useState([0, 0, 0, 0, 0, 0]);
	const [animateEvolving, setAnimateEvolving] = useState(start);
	useEffect(() => {
		getSliderValueByTime();
	}, []);

	const getSliderValueByTime = () => {
		const h = new Date().getHours();
		if (h < 5) {
			setSliderValue(600);
		} else if (h < 7) {
			setSliderValue(100);
		} else if (h < 10) {
			setSliderValue(200);
		} else if (h < 17) {
			setSliderValue(300);
		} else if (h < 20) {
			setSliderValue(400);
		} else if (h < 23) {
			setSliderValue(500);
		} else {
			setSliderValue(600);
		}
	};

	useEffect(() => {
		if (animateEvolving) {
			const interval = setInterval(() => {
				if (sliderValue < 700) {
					setSliderValue(sliderValue + 1);
				} else {
					setSliderValue(100);
				}
			}, 10);
			return () => clearInterval(interval);
		}
	}, [sliderValue, animateEvolving]);

	useEffect(() => {
		const r = Math.floor(sliderValue / 100);
		const res = [0, 0, 0, 0, 0, 0, 0];
		res[r - 1] = 1;
		res[r] = (sliderValue - r * 100) / 100;

		setOpacities(res);
	}, [sliderValue]);

	const animateTimeEvolving = () => {
		if (animateEvolving) {
			getSliderValueByTime();
		}
		setAnimateEvolving(!animateEvolving);
	};

	return { opacities, animateEvolving, animateTimeEvolving };
};

export default useTimeEvolving;
