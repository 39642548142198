import { useEffect, useState } from 'react';
import './AokiNft.css';
import TraitPopup from '../TraitPopup/TraitPopup';
import TraitPopup2 from '../TraitPopup/TraitPopup2';
import Media from '../Media/Media';

const AokiNft = ({ videoUrl, imageUrl, traits, style, autoStart, clientName, showForgeTrait, showRedeemableTrait }) => {
  const [mediaType, setMediaType] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);

  useEffect(() => {
    if (videoUrl && videoUrl.endsWith('.mp4')) {
      setMediaType('video');

      setMediaUrl(videoUrl);
    } else {
      setMediaType('image');

      setMediaUrl(imageUrl);
    }
  }, [videoUrl, imageUrl]);

  return (
    <Media
      autoPlay={true}
      mediaType={mediaType}
      type={'regular'}
      mediaUrl={mediaUrl}
      traits={traits}
      format={'portrait'}
      timebasedSlides={[]}
      style={style}
      displayFullScreen={true}
      autoStart={autoStart}
      clientName={clientName}
      traitPopup={
        <>
          {showForgeTrait && <TraitPopup></TraitPopup>}
          {showRedeemableTrait && <TraitPopup2></TraitPopup2>}
        </>
      }
      customWidth={1.1} //default 1.365
    />
  );
};

export default AokiNft;
