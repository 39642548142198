import React from "react";
import "./Spinner.css";

const Spinner8Bit = () => {
  return (
    <div className="la-ball-8bits la-3x" style={{ margin: "auto" }}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner8Bit;
