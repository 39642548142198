import React from "react";
import "./Spinner.css";

const Timer = () => {
	return (
		<div className="la-timer la-3x" style={{ margin: "auto" }}>
			<div></div>
		</div>
	);
};

export default Timer;
