
import { useEffect, useState, useRef } from 'react';
import ResizeObserver from "resize-observer-polyfill";

const useContainerDimensions = myRef => {

  const getDimensions = () => ({
    width: myRef.current && myRef.current.offsetWidth,
    height: myRef.current && myRef.current.offsetHeight
  })

  // const handleResize = () => {
  //   setDimensions(getDimensions());
  // }

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        // const { width, height } = entry.contentRect;
        // setDimensions({ width, height });
        setDimensions(getDimensions());
      });
    });

    if (myRef.current) {
      resizeObserverRef.current.observe(myRef.current);

      // if (myRef.current.nodeName == "IMG" && !myRef.current?.complete) {
      //   myRef.current.addEventListener('load', () => {
      //     return setDimensions(getDimensions())
      //   })
      // }
    }

    // window.addEventListener("resize", handleResize)

    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
      // window.removeEventListener("resize", handleResize)
    }
  }, [myRef, myRef.current])
  return dimensions;
}

export default useContainerDimensions;