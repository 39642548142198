import React, { forwardRef, useState, useEffect, useRef } from "react";
import useTimeEvolving from "../../hooks/useTimeEvolving.js";
import { useContainerDimensions } from "../../hooks";
import autoplayOn from "../../assets/images/autoplay-on.png";
import autoplayOff from "../../assets/images/autoplay-off.png";
import shadow from "../../assets/images/token-shadow.png";
import fullscreenIcon from "../../assets/images/fs.png";
import iconVideo from "../../assets/images/icon-video.svg";
import { Timer } from "../Spinner";

import "./Media.css";

const Image = forwardRef(
	(
		{
			mediaType,
			format,
			type,
			mediaUrl,
			timebasedSlides,
			isWallet,
			style,
			displayFullScreen,
			autoPlay,
			autoStart,
			clientName,
			traitPopup,
			customWidth
		},
		forwardedRef,
	) => {
		{
			const containerRef = useRef();
			const [playVideo, setPlayVideo] = useState(false);
			const [playError, setPlayError] = useState(false);
			const [loadingVideo, setLoadingVideo] = useState(false);
			const { width, height } = useContainerDimensions(containerRef);
			let start = false;
			if (autoStart) {
				start = true;
			}

			const {
				opacities,
				animateEvolving,
				animateTimeEvolving,
			} = useTimeEvolving(start);

			const [containerWidth, setContainerWidth] = useState(null);
			const [loaded, setLoaded] = useState(false);
			const [placeholderWidth, setPlaceholderWidth] = useState(null);
			const placeHolderImageRef = useRef();
			const childContainer = useRef();
			const imageRef = useRef();
			const videoRef = useRef(null);
			const evolvingContainer = useRef(null);
			const nftWidth = customWidth || 1.365
			const childContainerStyle = {
				width: width < height / nftWidth ? 100 + "%" : height / nftWidth,
				marginTop: width < height / nftWidth ? (height - width * nftWidth) / 2 : 0,
			};

			useEffect(() => {
				setContainerWidth(width);
			}, [width]);
			const requestFullScreen = () => {
				const ref =
					mediaType === "image"
						? imageRef && imageRef.current
						: videoRef && videoRef.current;
				if (ref) {
					if (ref.requestFullscreen) {
						ref.requestFullscreen();
					} else if (ref.webkitRequestFullscreen) {
						/* Safari */
						ref.webkitRequestFullscreen();
					} else if (ref.msRequestFullscreen) {
						/* IE11 */
						ref.msRequestFullscreen();
					}
				}
			};
			useEffect(() => {
				if (autoPlay) {
					setPlayVideo(true);
				}
			}, [autoPlay, playError]);
			useEffect(() => {
				if (playVideo) {
					if (videoRef.current && !playError) {
						videoRef.current.play();
						videoRef.current.currentTime = 0;
					}
				}
			}, [playVideo, videoRef, playError]);

			const renderedImages = (
				<>
					<div className="evolving-container" ref={evolvingContainer}>
						{timebasedSlides.map((img, i) => (
							<div
								key={i.toString()}
								className="img-container"
								style={{ opacity: opacities[i] }}
							>
								<img
									className="evolve-img"
									src={timebasedSlides[i]}
									alt={`Image ${i}`}
								/>
							</div>
						))}
					</div>
				</>
			);

			const playLandscape = () => {
				setPlaceholderWidth(placeHolderImageRef.current.offsetWidth);
				setLoaded(true);
			};

			useEffect(() => {
				if (loaded) {
					const childContainerWidth =
						childContainerStyle.width > 600
							? childContainerStyle.width
							: childContainer.current.offsetWidth;
					evolvingContainer.current.animate(
						[
							// keyframes
							{ transform: "translateX(0px)" },
							{
								transform: `translateX(${
									-placeholderWidth + childContainerWidth + "px"
								})`,
							},
							{ transform: "translateX(0px)" },
						],
						{
							// timing options
							duration: 40000,
							iterations: Infinity,
						},
					);
				}
			}, [width, placeHolderImageRef, loaded]);
			
			const portraitTBStyle = {
				width: "auto",
				position: "relative",
			};

			return (
				<div ref={containerRef} className={`parent-container ${isWallet ? "wallet" : ""} ${clientName}`}>
					{containerWidth > 0 && (
						<div
							className={`mx-auto ${isWallet ? "" : "p-4"} `}
							ref={childContainer}
							style={childContainerStyle}
						>
							<div
								ref={forwardedRef}
								className={
									format === "portrait"
										? "nft-container"
										: "landscape-container"
								}
								style={{...style, paddingTop: nftWidth*100+"%"}}
							>
								{displayFullScreen &&
									(mediaType === "image" ? (
										<img
											ref={imageRef}
											src={mediaUrl}
											onError={(e) => {
												e.target.onerror = null;
											}}
											alt=""
										/>
									) : (
										<video
											ref={videoRef}
											src={mediaUrl}
											onError={(e) => {
												e.target.onerror = null;
											}}
										/>
									))}
								{!loaded && format === "landscape" && (
									<div className="placeholder">
										<img
											alt=""
											src={mediaUrl}
											onLoad={playLandscape}
											ref={placeHolderImageRef}
										/>
									</div>
								)}

								{traitPopup}

								{mediaType === "image" ? (
									format === "portrait" ? (
										type === "regular" ? (
											<img src={mediaUrl} alt="" />
										) : (
											<>
												<div className="anim-evolv-div">
													<button
														className="anim-evolv-button"
														onClick={() => {
															animateTimeEvolving();
														}}
													>
														<img
															src={animateEvolving ? autoplayOn : autoplayOff}
															style={portraitTBStyle}
															onMouseOver={(e) =>
																animateEvolving
																	? (e.currentTarget.src = autoplayOff)
																	: (e.currentTarget.src = autoplayOn)
															}
															onMouseLeave={(e) =>
																animateEvolving
																	? (e.currentTarget.src = autoplayOn)
																	: (e.currentTarget.src = autoplayOff)
															}
															alt=""
														/>
													</button>
												</div>
												{renderedImages}
											</>
										)
									) : (
										<>
											{type === "regular" ? (
												<div
													className="evolving-container"
													ref={evolvingContainer}
												>
													<img src={mediaUrl} alt="" />
												</div>
											) : (
												<>
													<div className="anim-evolv-div">
														<button
															className="anim-evolv-button"
															onClick={() => {
																animateTimeEvolving();
															}}
														>
															<img
																src={animateEvolving ? autoplayOn : autoplayOff}
																onMouseOver={(e) =>
																	animateEvolving
																		? (e.currentTarget.src = autoplayOff)
																		: (e.currentTarget.src = autoplayOn)
																}
																onMouseLeave={(e) =>
																	animateEvolving
																		? (e.currentTarget.src = autoplayOn)
																		: (e.currentTarget.src = autoplayOff)
																}
																alt=""
															/>
														</button>
													</div>
													{renderedImages}
												</>
											)}
										</>
									)
								) : (
									<div>
										{!autoPlay && (
											<div
												className="play-btn-container"
												onMouseEnter={() => {
													!autoPlay && setPlayVideo(true);
												}}
												onMouseLeave={() => {
													!autoPlay && setPlayVideo(false);
												}}
											>
												<div
													style={{
														visibility: playVideo ? "hidden" : "visible",
													}}
												>
													<img src={mediaUrl} alt="" />
													<img className="play-btn" src={iconVideo} alt="" />
												</div>
											</div>
										)}

										{format === "portrait" ? (
											<video
												style={{ visibility: playVideo ? "visible" : "hidden" }}
												ref={videoRef}
												loop
												preload={"none"}
												muted
												autoPlay={autoPlay}
												playsInline
												onLoadStart={() => {
													setLoadingVideo(true);
												}}
												onCanPlayThrough={() => {
													setLoadingVideo(false);
												}}
												onError={() => {
													setPlayError(true);
												}}
												src={mediaUrl}
											/>
										) : (
											<video
												style={{ visibility: playVideo ? "visible" : "hidden" }}
												ref={videoRef}
												loop
												preload={"none"}
												muted
												autoPlay={autoPlay}
												playsInline
												onLoadStart={() => {
													setLoadingVideo(true);
												}}
												onCanPlayThrough={() => {
													setLoadingVideo(false);
												}}
												onError={() => {
													setPlayError(true);
												}}
												src={mediaUrl}
											/>
										)}

										{playVideo && loadingVideo && (
											<div className="spinner-container">
												<Timer></Timer>
											</div>
										)}
									</div>
								)}

								{displayFullScreen && (
									<div className="fullscreen-btn-container">
										<div
											className="fullscreen-btn btn"
											onClick={() => {
												requestFullScreen();
											}}
										>
											<img src={fullscreenIcon} alt="" />
										</div>
									</div>
								)}
							</div>
							<div className="token-shadow">
								<img src={shadow}></img>
							</div>
						</div>
					)}
				</div>
			);
		}
	},
);

export default Image;
